import { settings } from '../../config/index.mjs';
import { initialize as analyticsInitializer } from './analyticsBasic';
import { initialize as analyticsInitializerGDDL } from './analyticsGDDL';

export const getAnalyticsTracker = () => {
  if (settings.GDDL_ID && settings.GDDL_ID !== '') {
    return analyticsInitializerGDDL(settings.GDDL_ID);
  }
  return analyticsInitializer();
};
