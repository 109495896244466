import { map } from '../lodash-min';
import { hasWindow } from '../utils';

const eventTypes = {
  pageImpression: 'page-impression',
  buttonClick: 'button-click',
  toolStart: 'tool-start',
  toolSubmit: 'tool-submit'
};

const mapVehicleToAnalyticsData = (vehicle) => {
  return {
    reference: vehicle.reference,
    identification: vehicle.identification,
    make: vehicle.makeKey,
    model: vehicle.modelKey,
    modelFamily: vehicle.modelFamilyKey,
    bodyType: vehicle.bodyKey,
    labels: vehicle.labels,
    category: vehicle.categoryKey,
    fuel: vehicle.fuelKey,
    transmission: vehicle.gearboxKey,
    kw: vehicle.kw,
    hp: vehicle.hp,
    mileage: vehicle.mileage,
    firstRegistrationYear: vehicle.firstRegistrationYear,
    firstRegistrationMonth: vehicle.firstRegistrationMonth,
    price: vehicle.price,
    monthlyPrice: vehicle.monthlyPrice,
    monthlyPriceType: vehicle.monthlyPriceType,
    doors: vehicle.doors,
    seats: vehicle.seats,
    vat: vehicle.vat,
    equipments: map(vehicle.equipments, (eq) => eq.key),
    exteriorColor: vehicle.exteriorColorKey,
    interiorColor: vehicle.interiorColorKey,
    interiorUpholstery: vehicle.interiorUpholsteryKey,
    co2: vehicle.co2,
    euroNorm: vehicle.euroNorm,
    location: vehicle.dealer ? vehicle.dealer.name : ''
  };
};

const resolvePageInfo = (context, window) => {
  return {
    language: context ? context.culture : undefined,
    url: window.location.href,
    name: context?.page
  };
};

const analyticsInitialize = () => {
  // console.log('initialize analytics Autralis');
  // TagManager.initialize(tagManagerArgs);
};

const analyticsEventSubmit = (tracker, event) => {
  if (hasWindow() && tracker) {
    try {
      tracker.push(event);
    } catch (err) {
      // console.log('Event did not track correctly, reason:', err);
    }
  }
};

const buttonCtaEventInitialize = (props, context) => {
  return {
    event: eventTypes.buttonClick,
    page: resolvePageInfo(context, window),
    info: {
      buttonType: 'button',
      buttonCategory: props.buttonCategory ? props.buttonCategory : 'undefined-link',
      buttonName: props.name,
      buttonText: props.buttonText,
      buttonTarget: props.target,
      buttonActionUrl: props.url,
      buttonActionSearch: props.search
    }
  };
};

const toolStartEventInitialize = (type, data, context) => {
  const vehicle = data && data.vehicle ? mapVehicleToAnalyticsData(data.vehicle) : undefined;

  return {
    event: eventTypes.toolStart,
    page: resolvePageInfo(context, window),
    info: {
      toolType: 'request-form',
      // toolName: 'myway-test-drive',
      requestType: type,
      toolStep: data.toolStep ? data.toolStep : undefined,
      // toolStepOption: '',
      toolStepNumber: data.toolStepNumber ? data.toolStepNumber : undefined
      // leadID: '',
      //errorMessage: '',
      //errorType: ''
    },
    vehicle
  };
};

const toolSubmitEventInitialize = (type, data, context) => {
  const vehicle = data && data.vehicle ? mapVehicleToAnalyticsData(data.vehicle) : undefined;

  return {
    event: eventTypes.toolSubmit,
    page: resolvePageInfo(context, window),
    info: {
      toolType: 'request-form',
      // toolName: 'myway-test-drive',
      requestType: type,
      toolStep: data && data.toolStep ? data.toolStep : undefined,
      //toolStepOption: '',
      toolStepNumber: data && data.toolStepNumber ? data.toolStepNumber : undefined
      // leadID: '',
      //errorMessage: '',
      //errorType: ''
    },
    vehicle
  };
};

const pageImpressionEventInitialize = (page, data, context) => {
  let eventData = {};

  // Fix for backwords compatible
  let type = '';
  if (typeof page === 'string') {
    type = page;
  } else {
    type = page.type;
  }

  if (type === 'vehicle') eventData = { ...eventData, vehicle: mapVehicleToAnalyticsData(data) };

  return {
    event: eventTypes.pageImpression,
    page: resolvePageInfo(context, window),
    ...eventData
  };
};

export const initialize = () => {
  const tracker = hasWindow() ? window.dataLayer : undefined;
  const settings = {
    id: ''
  };

  return {
    name: 'GOOGLE_TAG_AUTRALIS',
    settings: settings,
    tracker: tracker,

    analyticsInitialize: (settings) => analyticsInitialize(settings),
    pageImpressionEventInitialize: (page, data, context, session) => pageImpressionEventInitialize(page, data, context, session),
    analyticsEventSubmit: (event) => analyticsEventSubmit(tracker, event),
    productDetailInitialize: () => {
      return { event: 'product-initialize' };
    },
    productImpressionInitialize: () => {
      return { event: 'product-impression' };
    },
    productClickInitialize: () => {
      return { event: 'product-click' };
    },
    buttonCtaEventInitialize: (props, context) => buttonCtaEventInitialize(props, context),
    toolStartEventInitialize: (type, data, context) => toolStartEventInitialize(type, data, context),
    toolSubmitEventInitialize: (type, data, context) => toolSubmitEventInitialize(type, data, context),
    toolCompleteEventInitialize: () => {
      return { event: 'tool-complete' };
    },
    toolErrorEventInitialize: () => {
      return { event: 'tool-error' };
    },
    searchQueryEventInitialize: () => {
      return { event: 'search-query' };
    },
    filterQueryEventInitialize: () => {
      return { event: 'search-query' };
    },
    searchResultclickEventInitialize: () => {
      return { event: 'search-resultclick' };
    },
    contentblockImpressionEventInitialize: () => {
      return { event: 'content-block-impression' };
    },
    notificationImpressionEventInitialize: () => {
      return { event: 'notification-impression' };
    },
    notificationClickEventInitialize: () => {
      return { event: 'notification-click' };
    },
    userLoginEventInitialize: () => {
      return { event: 'user-login' };
    },
    productAddToFavoriteInitialize: () => {
      return { event: 'product-add-to-favorite' };
    }
  };
};
