import { hasWindow } from '../utils';
import { pageNameToType, buttonCategoryMap, vehicleToCommerce, vehicleToCommerceClick, companyCodeToParams } from './analyticsGDDLmapping';
import analyticstracker from 'analyticstracker';

// eslint-disable-next-line
import analyticsTransQA from 'analytics-transQA';
// eslint-disable-next-line
import analyticsTransGTM from 'analytics-transGTM';

import TagManager from 'react-gtm-module';

const eventTypes = {
  pageImpression: 'page-impression',
  buttonClick: 'button-click',
  toolStart: 'tool-start',
  toolSubmit: 'tool-submit',
  toolComplete: 'tool-complete',
  toolError: 'tool-error',
  productDetail: 'product-detail',
  productImpression: 'product-impression',
  productClick: 'product-click',
  productAddToFavorite: 'product-add-to-favorite',
  searchQuery: 'search-query',
  searchResultclick: 'search-resultclick',
  contentblockImpression: 'content-block-impression',
  notificationImpression: 'notification-impression',
  notificationClick: 'notification-click',
  userLogin: 'user-login'
};

const EMPTY_COMMERCE = {
  carBrand: '',
  carModel: '',
  referenceID: '',
  expertReference: '',
  carConfigCode: '',
  carEquipmentLine: '',
  carEngineType: '',
  carEnginePower: '',
  carGearboxType: '',
  carExteriorColor: '',
  carInteriorColor: '',
  carOptionsList: '',
  carPrice: '',
  carTotalPrice: '',
  carYear: '',
  carMileage: ''
};

export const EMPTY_CARFILTER = {
  totalPrice: '',
  monthlyPrice: '',
  makeModels: '',
  category: '',
  fuel: '',
  engine: '',
  emission: '',
  firstRegistrationYear: '',
  mileage: '',
  kw: '',
  pk: '',
  transmission: '',
  doors: '',
  seats: '',
  equipment: '',
  vin: '',
  reference: '',
  customerType: '',
  euroNorm: ''
};

const analyticsInitialize = (settings) => {
  const tagManagerArgs = {
    gtmId: settings.id
  };
  TagManager.initialize(tagManagerArgs);
};

const analyticsEventSubmit = (tracker, event) => {
  if (hasWindow() && tracker) {
    try {
      let aTrack = tracker(); // this is an instance of analyticsTracker sent on initialization by the user of core
      aTrack.trackEvent(event);
    } catch (err) {
      console.log('Event did not track correctly, reason:', err);
    }
  }
};

const pageImpressionEventInitialize = (page, data, context, session) => {
  const parsePreviousPageData = (brand, platform) => {
    let history = window.history.state;

    const previousPageData = {
      previousPageName: '',
      previousPageUrl: '',
      previousPageFullUrl: '',
      previousPageType: '',
      previousPlatform: '',
      previousBrand: ''
    };

    // Added check to reload so the page event gets triggerd (if previousPageInfo == newPageInfo the event doesn't get triggerd.)
    // So when you relead now the previous page will be empty wich will trigger the page impression
    // Because if the pageImpression is not triggerd and the GDDL tracker doesn't have the page info all other events won't be triggerd aswell
    if (history?.previousPageInfo && window?.performance?.getEntriesByType('navigation')?.[0]?.type != 'reload') {
      const fullUrl = window.location.origin + history?.previousPageInfo.url;
      previousPageData.previousPageName = history?.previousPageInfo.analyticsName;
      previousPageData.previousPageUrl = fullUrl.split('?')[0];
      previousPageData.previousPageFullUrl = fullUrl;
      previousPageData.previousPageType = history?.previousPageInfo.analyticsType;
      previousPageData.previousPlatform = platform;
      previousPageData.previousBrand = brand;
    }

    return previousPageData;
  };

  const parseDealerData = (dealer, dealerGroup, dealerGroups) => {
    const dealerData = {
      dealerGroupID: dealerGroup?.externalReference || dealer?.group?.externalReference || dealerGroups?.[0]?.externalReference || '',
      dealerGroupName: dealerGroup?.name || dealer?.group?.name || dealerGroups?.[0]?.name || '',
      dealerID: dealer?.externalReference || '',
      dealerName: dealer?.name || '',
      dealerLocation: dealer ? `${dealer?.address}, ${dealer?.postal} ${dealer?.city}` : ''
    };

    return dealerData;
  };

  const getCompanyParams = (companyCode) => {
    let companyCodeParams;
    let pageType;

    switch (companyCode) {
      case 'myway': {
        companyCodeParams = companyCodeToParams.myway;
        pageType = pageNameToType.myway[page.analyticsName];
        break;
      }
      case 'aaplus': {
        companyCodeParams = companyCodeToParams.aaplus;
        pageType = pageNameToType.myway[page.analyticsName];
        break;
      }
      case 'mywayb2b': {
        companyCodeParams = companyCodeToParams.mywayb2b;
        pageType = undefined;
        break;
      }
      default: {
        companyCodeParams = companyCodeToParams.default;
        pageType = pageNameToType.default[page.analyticsName];
      }
    }

    return { companyCodeParams, pageType };
  };

  const generateEventObject = (page, data, context, session) => {
    if (!page?.analyticsName) {
      return null;
    }
    let gddlPageData = {
      author: 'Autralis',
      initialReferrer: document?.referrer || '', // the page where you originally came from before landing on the myway/lma website
      pageVersion: '',
      language: context?.culture || '',
      // TODO check if needed
      expertReference: data?.reference || '',
      labels: data?.labels?.join('|') || ''
    };

    // Adding page name and secitons
    let pageName = page?.analyticsName;

    if (page?.analyticsName?.startsWith('myway/')) {
      // TODO change page names in builder of myway to the correct one so we don't need this line of code
      page?.analyticsName?.replace('myway/', 'main/');
    }

    if (page.category == 'productDetail') {
      pageName = pageName.replace('{model}', `${data?.make} ${data?.model}`);
    } else if (page.type == 'faqCategory') {
      pageName = pageName.replace('{category}', context?.data?.category?.slug);
    } else if (page.type == 'faqTopic') {
      pageName = pageName.replace('{topic}', context?.data?.topic?.slug);
    }

    gddlPageData.pageName = pageName;

    const splitNameArray = pageName.split('/');
    const [, section = '', subSectionOne = '', subSectionTwo = '', subSectionThree = ''] = splitNameArray;

    gddlPageData.section = section;
    gddlPageData.subSectionOne = subSectionOne;
    gddlPageData.subSectionTwo = subSectionTwo;
    gddlPageData.subSectionThree = subSectionThree;

    let { companyCodeParams, pageType } = getCompanyParams(context?.companyCode);

    // Page Type
    gddlPageData.pageType = page.analyticsType || pageType;

    let { brand, platform, pageviewMode } = companyCodeParams;
    if (context?.page?.includes('dealerGroup')) pageviewMode = 'dealer';

    gddlPageData.brand = brand;
    gddlPageData.platform = platform;
    gddlPageData.pageviewMode = pageviewMode;

    // Urls
    gddlPageData.pageUrl = window.location.href.split('?')[0];
    gddlPageData.pageFullUrl = window.location.href;

    // Login
    gddlPageData.loginStatus = session?.authenticated ? 'logged in' : 'not logged in';

    // Previous page data and DealerData
    gddlPageData = {
      ...gddlPageData,
      ...parsePreviousPageData(brand, platform),
      ...parseDealerData(data?.dealer, context?.dealerGroup, context?.dealerGroups)
    };

    return {
      event: eventTypes.pageImpression,
      info: gddlPageData
    };
  };

  try {
    return generateEventObject(page, data, context, session);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error('pageImpressionEventInitialize \n', error);
    return null;
  }
};

const buttonCtaEventInitialize = (props) => {
  const generateEventObject = (props) => {
    let buttonTarget;
    if (props.buttonTarget === 'inbound-link' || props.buttonTarget === 'internal-link') {
      if (props.buttonTarget || props.page) {
        buttonTarget = window.location.origin + props.buttonTarget || props.page;
      } else {
        buttonTarget = props.url;
      }
    } else {
      buttonTarget = props.url || props.buttonActionUrl || props.page;
    }
    return {
      event: eventTypes.buttonClick,
      info: {
        buttonName: props.analyticsButtonName || props.buttonName || props.name || '',
        buttonType: props.analyticsButtonType || props.buttonType || 'button',
        buttonCategory: props.analyticsButtonCategory || (props.buttonCategory ? buttonCategoryMap[props.buttonCategory] : 'undefined-link'),
        buttonText: props.analyticsButtonText || props.buttonText || props.text || '',
        buttonTarget: props.analyticsButtonTarget || buttonTarget || '',
        buttonPosition: props.analyticsButtonPosition || props.position || ''
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const productDetailInitialize = (props, context) => {
  console.info('productDetailInitialize');
  const generateEventObject = (props) => {
    const commerce = vehicleToCommerce(props.vehicle);

    return {
      event: eventTypes.productDetail,
      commerce: commerce
    };
  };

  try {
    return generateEventObject(props, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const productImpressionInitialize = (props, context) => {
  console.info('productImpressionInitialize');
  const generateEventObject = (props) => {
    const commerce = vehicleToCommerce(props.vehicle);

    return {
      event: eventTypes.productImpression,
      commerce: commerce
    };
  };

  try {
    return generateEventObject(props, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const productClickInitialize = (props, context) => {
  const generateEventObject = (props) => {
    const commerce = vehicleToCommerceClick(props.vehicle);

    return {
      event: eventTypes.productClick,
      commerce: commerce
    };
  };

  try {
    return generateEventObject(props, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const productAddToFavoriteInitialize = (props, context) => {
  const generateEventObject = (props) => {
    const commerce = vehicleToCommerceClick(props.vehicle);

    return {
      event: eventTypes.productAddToFavorite,
      commerce: commerce,
      info: {
        reference: props.reference
      }
    };
  };

  try {
    return generateEventObject(props, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const toolStartEventInitialize = (type, data, context) => {
  const generateEventObject = (type, data) => {
    const commerce = data.vehicle ? vehicleToCommerce(data.vehicle) : EMPTY_COMMERCE;

    return {
      event: eventTypes.toolStart,
      info: {
        toolType: data.toolType || '',
        toolName: data.toolName || '',
        toolStep: data.toolStepStart || '',
        toolStepOption: '',
        toolStepNumber: data.toolStepNumber || '1',
        requestType: data.requestType || '',
        leadID: '',
        errorMessage: '',
        errorType: ''
      },
      commerce: commerce
    };
  };

  try {
    return generateEventObject(type, data, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const toolSubmitEventInitialize = (type, data, context) => {
  const generateEventObject = (type, data) => {
    const additionalData = data?.additionalData ? data.additionalData : data; //sometimes it's in additionalData sometimes in data...
    const commerce = additionalData?.vehicle ? vehicleToCommerce(additionalData?.vehicle) : EMPTY_COMMERCE;

    return {
      event: eventTypes.toolSubmit,
      info: {
        toolType: additionalData?.toolType || '',
        toolName: additionalData?.toolName || '',
        toolStep: additionalData?.toolStepStart || '',
        toolStepOption: additionalData?.toolStepOption || '',
        toolStepNumber: additionalData.toolStepNumber || '1',
        requestType: additionalData.requestType || '',
        leadID: '',
        errorMessage: '',
        errorType: ''
      },
      commerce: commerce
    };
  };

  try {
    return generateEventObject(type, data, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const toolCompleteEventInitialize = (props) => {
  const generateEventObject = (props) => {
    const commerce = props.vehicle ? vehicleToCommerce(props.vehicle) : EMPTY_COMMERCE;

    return {
      event: eventTypes.toolComplete,
      info: {
        toolType: props.toolType || '',
        toolName: props.toolName || '',
        toolStep: props.toolStep || props.toolStepStart || '',
        toolStepOption: props.toolStepOption || '',
        toolStepNumber: props.toolStepNumber || '2',
        requestType: props.requestType || '',
        leadID: '',
        errorMessage: '',
        errorType: ''
      },
      commerce: commerce
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const toolErrorEventInitialize = (type, data, context) => {
  const generateEventObject = (type, data) => {
    const additionalData = data;
    const commerce = additionalData?.vehicle ? vehicleToCommerce(additionalData?.vehicle) : EMPTY_COMMERCE;
    const errorMessageString = data.errors ? (typeof data.errors == 'string' ? data.errors : Object.values(data.errors).join(', ')) : '';
    return {
      event: eventTypes.toolError,
      info: {
        toolType: additionalData?.toolType || '',
        toolName: additionalData?.toolName || '',
        toolStep: additionalData?.toolStepError || additionalData?.toolStepStart || '',
        toolStepOption: additionalData?.toolStepOption || '',
        toolStepNumber: additionalData?.toolStepNumber || '1',
        requestType: additionalData.requestType || '',
        leadID: '',
        errorMessage: errorMessageString ? `The following errors were thrown: ${errorMessageString}` : '',
        errorType: data.errorType || ''
      },
      commerce: commerce
    };
  };

  try {
    return generateEventObject(type, data, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const tranformFilters = (filters) => {
  if (!filters || !Array.isArray(filters)) return {};
  const intermediateFilter = filters.reduce(function (r, filter) {
    r[filter.type] = r[filter.type] || [];
    let value;
    if (Array.isArray(filter.value)) {
      value = filter.value.join('-');
    } else {
      if (filter.type === 'makeModel') {
        value = filter.value?.replace('|', '-');
      } else {
        value = filter.value;
      }
    }

    r[filter.type].push(value);
    return r;
  }, Object.create(null));

  let formattedFilters = {};
  for (const [key, value] of Object.entries(intermediateFilter)) {
    formattedFilters[key] = value.join('|');
  }

  const filtersWithCorrectKeys = EMPTY_CARFILTER;
  filtersWithCorrectKeys['totalPrice'] = formattedFilters['price'] || '';
  filtersWithCorrectKeys['monthlyPrice'] = formattedFilters['monthlyPrice'] || '';
  filtersWithCorrectKeys['makeModels'] = formattedFilters['makeModel'] || '';
  filtersWithCorrectKeys['category'] = formattedFilters['body'] || '';
  filtersWithCorrectKeys['engine'] = formattedFilters['fuel'] || '';
  filtersWithCorrectKeys['firstRegistrationYear'] = formattedFilters['firstRegistrationYear'] || '';
  filtersWithCorrectKeys['mileage'] = formattedFilters['mileage'] || '';
  filtersWithCorrectKeys['pk'] = formattedFilters['pk'] || '';
  filtersWithCorrectKeys['kw'] = formattedFilters['kw'] || '';
  filtersWithCorrectKeys['transmission'] = formattedFilters['transmission'] || '';
  filtersWithCorrectKeys['doors'] = formattedFilters['doorsRange'] || '';
  filtersWithCorrectKeys['seats'] = formattedFilters['seatsRange'] || '';
  filtersWithCorrectKeys['equipment'] = formattedFilters['equipments'] || '';
  filtersWithCorrectKeys['euroNorm'] = formattedFilters['euroNorm'] || '';
  filtersWithCorrectKeys['emission'] = formattedFilters['co2Range'] || '';
  filtersWithCorrectKeys['reference'] = formattedFilters['identification'] || '';
  filtersWithCorrectKeys['customerType'] = formattedFilters['customerType'] || '';
  filtersWithCorrectKeys['warrantyRange'] = formattedFilters['warrantyRange'] || '';
  filtersWithCorrectKeys['labels'] = formattedFilters['label'] || '';
  filtersWithCorrectKeys['available'] = formattedFilters['available'] || '';

  return filtersWithCorrectKeys;
};

const searchQueryEventInitialize = (props) => {
  const generateEventObject = (props) => {
    const filtersWithCorrectKeys = tranformFilters(props.filters);
    return {
      event: eventTypes.searchQuery,
      info: {
        searchType: 'filter',
        searchKeyword: props.searchKeyword || '',
        searchResult: props.searchResult || '',
        searchResultPosition: props.searchResultPosition || '',
        searchResultCount: props.searchResultCount?.toString() || '',
        filter: filtersWithCorrectKeys || EMPTY_CARFILTER
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    console.log('ERROR: ', error);
    return {};
  }
};

const filterQueryEventInitialize = (props) => {
  const generateEventObject = (props) => {
    const filtersWithCorrectKeys = tranformFilters(props.filters);

    return {
      event: eventTypes.searchQuery,
      info: {
        searchType: props.searchType || 'filter',
        searchKeyword: props.searchKeyword || '',
        searchResult: props.searchResult || '',
        searchResultPosition: props.searchResultPosition || '',
        searchResultCount: props.count?.toString() || '',
        filter: filtersWithCorrectKeys || EMPTY_CARFILTER
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const searchResultclickEventInitialize = (props) => {
  const generateEventObject = (props) => {
    const filtersWithCorrectKeys = tranformFilters(props.searchResultFilter);

    return {
      event: eventTypes.searchResultclick,
      info: {
        searchType: 'filter',
        searchKeyword: props.searchKeyword || '',
        searchResult: props.searchResult || '',
        searchResultPosition: props.searchResultPosition?.toString() || '',
        searchResultCount: props.searchResultCount?.toString() || '',
        filter: filtersWithCorrectKeys || EMPTY_CARFILTER
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const contentblockImpressionEventInitialize = (containerOrContainerName) => {
  const generateEventObject = (containerOrContainerName) => {
    if (typeof containerOrContainerName === 'string') {
      return {
        event: eventTypes.contentblockImpression,
        info: {
          containerName: containerOrContainerName || '',
          containerPosition: '',
          containerVersion: ''
        }
      };
    }

    const container = containerOrContainerName;
    return {
      event: eventTypes.contentblockImpression,
      info: {
        containerName: container.analyticsName || container.name || '',
        containerPosition: container.order || '',
        containerVersion: ''
      }
    };
  };

  try {
    return generateEventObject(containerOrContainerName);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const notificationImpressionEventInitialize = (props) => {
  const generateEventObject = (props) => {
    return {
      event: eventTypes.notificationImpression,
      info: {
        notificationType: props.notificationType || 'banner',
        notificationCategory: props.notificationCategory || '',
        notificationName: props.notificationName || '',
        notificationOption: props.notificationOption || ''
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const notificationClickEventInitialize = (props) => {
  const generateEventObject = (props) => {
    return {
      event: eventTypes.notificationClick,
      info: {
        notificationType: props.notificationType || 'banner',
        notificationCategory: props.notificationCategory || '',
        notificationName: props.notificationName || '',
        notificationOption: props.notificationOption || 'close'
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const userLoginEventInitialize = (data) => {
  const generateEventObject = (data) => {
    return {
      event: eventTypes.userLogin,
      info: {
        customerID: data.customerId,
        customerType: data.customerType || '',
        customerDealer: data.customerDealer || '',
        loginType: data.loginType || ''
      }
    };
  };

  try {
    return generateEventObject(data);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

export const initialize = (GDDL_ID) => {
  const tracker = hasWindow() ? analyticstracker : undefined;

  return {
    name: 'GDDL',
    settings: {
      id: GDDL_ID
    },
    tracker: tracker,
    analyticsInitialize: (settings) => analyticsInitialize(settings),
    pageImpressionEventInitialize: (page, data, context, session) => pageImpressionEventInitialize(page, data, context, session),
    analyticsEventSubmit: (event) => analyticsEventSubmit(tracker, event),
    productDetailInitialize: (props, context) => productDetailInitialize(props, context),
    productImpressionInitialize: (props, context) => productImpressionInitialize(props, context),
    productClickInitialize: (props, context) => productClickInitialize(props, context),
    productAddToFavoriteInitialize: (props, context) => productAddToFavoriteInitialize(props, context),
    buttonCtaEventInitialize: (props, context) => buttonCtaEventInitialize(props, context),
    toolStartEventInitialize: (type, data, context) => toolStartEventInitialize(type, data, context),
    toolSubmitEventInitialize: (type, data, context) => toolSubmitEventInitialize(type, data, context),
    toolCompleteEventInitialize: (props) => toolCompleteEventInitialize(props),
    toolErrorEventInitialize: (type, data, context) => toolErrorEventInitialize(type, data, context),
    searchQueryEventInitialize: (props) => searchQueryEventInitialize(props),
    filterQueryEventInitialize: (props) => filterQueryEventInitialize(props),
    searchResultclickEventInitialize: (props) => searchResultclickEventInitialize(props),
    contentblockImpressionEventInitialize: (context) => contentblockImpressionEventInitialize(context),
    notificationImpressionEventInitialize: (props) => notificationImpressionEventInitialize(props),
    notificationClickEventInitialize: (props) => notificationClickEventInitialize(props),
    userLoginEventInitialize: (data, context) => userLoginEventInitialize(data, context)
  };
};
