import { useState, useEffect } from 'react';
import { hasWindow } from '../utils.mjs';
import { useAnalytics } from 'dealer-website-components';

/**
 * [Function to check if a html element is currently visible in the viewport]
 * Also automaticly sends contentblockImpressionEvent for GDDL
 * @param  {[html element]} element [The element to be tracked]
 * @param  {object} block [name that the contentblockImpressionEvent needs to use]
 * @return {boolean}      [the current visibility of the html element]
 */
export const useContentBlockImpressionWhenInViewport = (element, block) => {
  const { analyticsTracker } = useAnalytics();
  const [isVisible, setIsVisible] = useState(false);

  const handleIsIntersecting = (isIntersecting) => {
    if (isIntersecting) setIsVisible(isIntersecting);

    if (analyticsTracker && block) {
      const contentBlockEvent = analyticsTracker.contentblockImpressionEventInitialize(block);
      analyticsTracker.analyticsEventSubmit(contentBlockEvent);
    }
  };

  useEffect(() => {
    if (!block.analyticsName || isVisible || !hasWindow() || !element?.current || !analyticsTracker) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // only fire the event once
          handleIsIntersecting(entry.isIntersecting);
          observer.unobserve(element.current);
        }
      },
      { rootMargin: '0px', threshold: 0.2 }
    );

    element?.current && observer.observe(element.current);

    return () => element?.current && observer.unobserve(element.current);
  }, [element.current, analyticsTracker]);

  return isVisible;
};
