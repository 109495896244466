import React, { useRef } from 'react';
import { getKeyValueContentFieldsForBlock } from '../utils';
import * as components from 'dealer-website-components';
import { useContentBlockImpressionWhenInViewport } from './utils';

const Block = (props) => {
  const { block, context, store, overridePageMetaData, resolveAnalyticsData, pageVariables } = props;
  const ref = useRef();
  useContentBlockImpressionWhenInViewport(ref, block);

  const Component = components[block.variant];
  if (!Component) {
    return null;
  }

  return (
    <div id={`b${block.order}${block.variant}`} ref={ref}>
      <Component
        {...getKeyValueContentFieldsForBlock(block, context.culture)}
        {...context.properties}
        {...pageVariables} // f.e 'reference on vehicle detail page
        context={context}
        store={store}
        overridePageMetaData={overridePageMetaData}
        resolveAnalyticsData={resolveAnalyticsData}
        location={props.location}
      />
    </div>
  );
};
export default Block;
